import axios from "axios";
import store from "@/store";

const api = axios.create({
  baseURL: process.env.VUE_APP_API_URL,
  withCredentials: true,
  headers: {
    Accept: "application/json",
  },
});

api.interceptors.request.use(
  (config) => {
    const token = store.state.auth.token;
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (e) => {
    return Promise.reject(e);
  }
);

api.interceptors.response.use(
  (response) => {
    return response;
  },
  (e) => {
    if (e.response && e.response.status === 401) {
      store.dispatch("unsetUserToken");
    }
    return Promise.reject(e);
  }
);

export default api;
