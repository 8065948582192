export default {
  state: {
    all: [
      { id: 1, title: "Студия" },
      { id: 2, title: "1" },
      { id: 3, title: "2" },
      { id: 4, title: "3" },
      { id: 5, title: "4" },
      { id: 6, title: "5" },
    ],
  },
  mutations: {},
  actions: {},
};
