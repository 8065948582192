import Vue from "vue";
import Vuex from "vuex";

import projects from "./modules/projects";
import flats from "./modules/flats";
import cities from "./modules/cities";
import auth from "./modules/auth";
import bedrooms from "./modules/bedrooms";
import purchasedFlats from "./modules/purchasedFlats";
import appConfig from "./modules/appConfig";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    domain: process.env.VUE_APP_DOMAIN,
    storeUrl: process.env.VUE_APP_STORE_URL,
    storageUrl: `https://io-russia.ru/laravel/storage/app/`,

    loading: false,
  },
  getters: {
    percentRate(state, getters, rootState) {
      const appConfig = rootState.appConfig.config;
      const currentCity = rootState.cities.current;

      return currentCity?.percent_rate ?? appConfig?.global_rate;
    },
    phone(state, getters, rootState) {
      const appConfig = rootState.appConfig.config;
      const currentCity = rootState.cities.current;

      return currentCity?.phone ?? appConfig?.global_phone;
    },
    email(state, getters, rootState) {
      const appConfig = rootState.appConfig.config;
      const currentCity = rootState.cities.current;

      return currentCity?.email ?? appConfig?.global_email;
    },
  },
  mutations: {
    LOADING(state, payload) {
      state.loading = payload;
    },
  },
  actions: {},
  modules: {
    projects,
    flats,
    cities,
    auth,
    bedrooms,
    purchasedFlats,
    appConfig,
  },
});
