/**
 * router/adminRoutes.js
 */

const adminRoutes = [
  {
    path: "/admin/projects",
    name: "admin.projects",
    meta: { auth: true, roles: ["admin", "clerk"] },
    component: () => import("../views/Admin/ProjectsView.vue"),
  },
  {
    path: "/admin/projects/:id",
    name: "admin.project.detail",
    meta: { auth: true, roles: ["admin", "clerk"] },
    component: () => import("../views/Admin/ProjectDetailAdmin.vue"),
  },
  {
    path: "/admin/flats",
    name: "admin.flats",
    meta: { auth: true, roles: ["admin", "clerk"] },
    component: () => import("../views/Admin/FlatsView.vue"),
  },
  {
    path: "/admin/flats/:id",
    name: "admin.flat.detail",
    meta: { auth: true, roles: ["admin", "clerk"] },
    component: () => import("../views/Admin/FlatDetailAdmin.vue"),
  },
  {
    path: "/admin/cities",
    name: "admin.cities",
    meta: { auth: true, roles: ["admin"] },
    component: () => import("../views/Admin/CitiesEditor.vue"),
  },
  {
    path: "/admin/offices",
    name: "admin.offices",
    meta: { auth: true, roles: ["admin"] },
    component: () => import("../views/Admin/OfficesEditor.vue"),
  },
  {
    path: "/admin/main",
    name: "admin.main",
    meta: { auth: true, roles: ["admin"] },
    component: () => import("../views/Admin/AppConfig.vue"),
  },
];

export default adminRoutes;
