import api from "@/api/axios";

export default {
  state: {
    all: [],
    flat: null,
  },
  mutations: {
    SET_ALL_FLATS(state, payload) {
      state.all = payload;
    },
    SET_FLAT(state, payload) {
      state.flat = payload;
    },
  },
  actions: {
    async getFlats({ rootState, commit }, params = {}) {
      try {
        params.city_id = rootState.cities.current.id;

        const { data } = await api.get("flats", { params });
        commit("SET_ALL_FLATS", data);
      } catch (e) {
        console.error(e);
        throw e;
      }
    },
    async getFlatById({ commit }, flatId) {
      try {
        const { data } = await api.get(`flats/${flatId}`);
        commit("SET_FLAT", data);
      } catch (e) {
        console.error(e);
        throw e;
      }
    },
    async updateFlat(state, flat) {
      flat.lat_long = flat.lat_long.toString();
      flat.project_id = flat.project.id;
      flat.city_id = flat.city.id;
      await api.put(`flats/${flat.id}`, flat);
    },
  },
};
