import api from "@/api/axios";

export default {
  state: {
    user: null,
    token: localStorage.getItem("user_token") ?? null,
  },
  getters: {
    isAdmin: (state) =>
      state.user?.roles.some((role) => role.name === "admin") ?? false,
    isManager: (state) =>
      state.user?.roles.some((role) => role.name === "manager") ?? false,
    isClerk: (state) =>
      state.user?.roles.some((role) => role.name === "clerk") ?? false,
    isPartner: (state) =>
      state.user?.roles.some((role) => role.name === "partner") ?? false,
    hasAnyRole: (state) => (roles) => {
      return (
        state.user?.roles.some((userRole) => roles.includes(userRole.name)) ??
        false
      );
    },
  },
  mutations: {
    SET_USER(state, payload) {
      state.user = payload;
    },
    SET_USER_TOKEN(state, payload) {
      state.token = payload;
      localStorage.setItem("user_token", payload);
    },
  },
  actions: {
    async getUser({ state, commit }) {
      try {
        if (!state.token) return;
        const { data } = await api.get("user");
        commit("SET_USER", data);
      } catch (e) {
        console.error(e);
        throw e;
      }
    },
    async register({ commit, dispatch }, credentials) {
      try {
        const { data } = await api.post("register", credentials);
        commit("SET_USER_TOKEN", data);
        await dispatch("getUser");
      } catch (e) {
        console.error(e);
        throw e;
      }
    },
    async login({ commit, dispatch }, credentials) {
      try {
        const { data } = await api.post("login", credentials);
        commit("SET_USER_TOKEN", data);
        await dispatch("getUser");
      } catch (e) {
        console.error(e);
        throw e;
      }
    },
    async logout({ state, dispatch }) {
      if (!state.token) return;
      try {
        await api.post("logout");
        await dispatch("unsetUserToken");
      } catch (e) {
        console.error(e);
        throw e;
      }
    },
    unsetUserToken({ commit }) {
      commit("SET_USER", null);
      commit("SET_USER_TOKEN", null);
      localStorage.removeItem("user_token");
      delete api.defaults.headers.common["Authorization"];
    },
  },
};
