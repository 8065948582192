<template>
  <div id="app">
    <main-nav-top />
    <sidebar-menu />

    <div v-if="loading" class="spiner-wrapper">
      <b-spinner variant="primary" label="Spinning"></b-spinner>
    </div>

    <div class="height-100">
      <router-view />
    </div>

    <footer-component />
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import api from "./api/axios";
import FooterComponent from "./components/FooterComponent.vue";
import MainNavTop from "./components/MainNavTop.vue";
import SidebarMenu from "./components/SidebarMenu.vue";

export default {
  components: { MainNavTop, SidebarMenu, FooterComponent },
  data: () => ({
    loading: false,
  }),
  computed: {
    ...mapState({}),
  },
  methods: {
    ...mapActions(["getCities", "initCurrentCity", "getUser", "getAppConfig"]),
  },
  async created() {
    try {
      this.loading = true;

      await api.get(process.env.VUE_APP_CSRF);

      this.getAppConfig();
      this.getUser();

      // Проверяем параметр city_id в URL и устанавливаем текущий город
      const cityIdParam = this.$route.query.city_id;
      if (cityIdParam) {
        localStorage.setItem("city_id", cityIdParam);
      }
      await this.getCities();
      this.initCurrentCity();
    } catch (error) {
      console.error(error);
    } finally {
      this.loading = false;
    }
  },
};
</script>

<style lang="scss">
.spiner-wrapper {
  position: fixed;
  z-index: 1000;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  backdrop-filter: saturate(0%);
  background-color: rgba($color: $zinc300, $alpha: 0.5);
  height: 100%;
  width: 100%;

  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
