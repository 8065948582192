<template>
  <router-link
    class="project"
    :to="admin ? `/admin/projects/${project.id}` : `/projects/${project.id}`"
  >
    <div class="project-preview__img-wrapper">
      <img
        v-if="project.previewImage"
        class="project-preview__img"
        :src="`${$store.state.storeUrl}app/projects/${project.previewImage.name}`"
      />

      <img
        v-else
        class="project-preview__img default"
        :src="require(`@/assets/images/svg/building.svg`)"
      />

      <div v-if="project.pay_from > 0" class="project-preview__pay-from-block">
        <p class="project-preview__pay-from">
          от {{ printPay(project.pay_from) }}
        </p>
      </div>
    </div>

    <div class="project-preview__text">
      <p class="project-preview__title">{{ project.title }}</p>
      <p v-if="project.address" class="project-preview__adress">
        {{ project.address }}
      </p>
    </div>
  </router-link>
</template>

<script>
export default {
  props: {
    project: Object,
    admin: Boolean,
  },
  computed: {},
  methods: {
    printPay(value) {
      return new Intl.NumberFormat("ru-RU", {
        style: "currency",
        currency: "RUB",
        maximumSignificantDigits: 10,
      }).format(value);
    },
  },
};
</script>

<style lang="scss">
.project {
  cursor: pointer;
  display: block;

  margin-bottom: 30px;
  padding-bottom: 30px;

  border-radius: $radius;

  outline: 2px solid;
  outline-color: rgba($color: $accent, $alpha: 0);

  transition: all ease-out 300ms;
}
.project:hover {
  outline: 2px solid;
  outline-color: rgba($color: $accent, $alpha: 0.2);

  box-shadow: $shadow-accent;

  transition: all ease-out 50ms;

  .project-preview__pay-from-block {
    background-color: rgba($color: $white, $alpha: 1);
  }

  .project-preview__img-wrapper {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
    transition: all ease-out 100ms;
  }
}
.project-preview__img-wrapper {
  position: relative;

  overflow: hidden;

  padding-bottom: 100%;
  width: 100%;
  border-radius: $radius;

  margin-bottom: 15px;
  background-color: rgba($color: $accent, $alpha: 0.1);

  transition: all ease-out 300ms;
}
.project-preview__img {
  position: absolute;

  height: 100%;
  width: 100%;

  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  object-fit: cover;
}

.project-preview__img.default {
  width: 50%;
  object-fit: contain;
}

.project-preview__pay-from-block {
  position: absolute;
  bottom: 15px;
  right: 15px;

  border-radius: $radius;

  background-color: rgba($color: $white, $alpha: 0.95);
}
.project-preview__pay-from {
  padding: 10px 30px;
  font-weight: 500;
}
.project-preview__text {
  padding-left: 15px;
}
.project-preview__title {
  font-size: 22px;
  font-weight: 800;
}
.project-preview__adress {
  font-size: 18px;
  font-weight: 400;
}
</style>
