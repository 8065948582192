/**
 * router/baseRoutes.js
 */

import HomeView from "../views/HomeView.vue";
import store from "@/store";

const baseRoutes = [
  // Home
  {
    path: "/",
    name: "home",
    component: HomeView,
  },
  // Error page
  {
    path: "/error",
    name: "error",
    component: () => import("../views/ErrorView.vue"),
    props: true,
  },
  // Auth
  {
    path: "/login",
    name: "login",
    component: () => import("../views/LoginView.vue"),
    beforeEnter: async (to, from, next) => {
      const token = to.query.token;

      await store.dispatch("getUser");
      const user = store.state.auth.user;

      if (token) {
        store.commit("SET_USER_TOKEN", token);
        await store.dispatch("getUser");
        return next("/");
      } else if (user) {
        next("/");
      } else {
        return next();
      }
    },
  },
  {
    path: "/register",
    name: "register",
    component: () => import("../views/RegisterView.vue"),
    beforeEnter: async (to, from, next) => {
      await store.dispatch("getUser");
      const user = store.state.auth.user;

      if (user) {
        next("/");
      } else {
        next();
      }
    },
  },
  // Projects
  {
    path: "/projects",
    name: "projects",
    component: () => import("../views/Projects/ProjectsView.vue"),
  },
  {
    path: "/projects/:id",
    name: "project.detail",
    component: () => import("../views/Projects/ProjectDetailView.vue"),
  },
  // Flats
  {
    path: "/flats",
    name: "flats",
    component: () => import("../views/Flats/FlatsView.vue"),
  },
  {
    path: "/flats/:id",
    name: "flat.detail",
    component: () => import("../views/Flats/FlatDetailView.vue"),
  },
  // Mortgage
  {
    path: "/mortgage",
    name: "mortgage",
    component: () => import("../views/MortgageView.vue"),
  },
  // Contacts
  {
    path: "/contacts",
    name: "contacts",
    component: () => import("../views/ContactsView.vue"),
  },

  {
    path: "/purchased-flats",
    name: "purchasedFlats",
    meta: { auth: true, roles: ["admin", "clerk", "employee", "partner"] },
    component: () => import("../views/PurchasedFlatsView.vue"),
  },
];

export default baseRoutes;
