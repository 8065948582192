<template>
  <div class="main-nav-top">
    <!-- Desktop -->
    <b-container class="d-none d-lg-block">
      <b-row class="global-nav" align-h="between" align-v="center">
        <b-col cols="12" md="auto">
          <router-link class="global-nav__link" to="/partners"
            >Партнерам</router-link
          >
        </b-col>

        <b-col cols="12" md="auto">
          <b-dropdown
            class="global-nav__link"
            variant="link"
            v-if="currentCity"
            :text="currentCity.title"
          >
            <b-dropdown-item
              :active="currentCity.id == city.id"
              v-for="(city, index) in cities"
              :key="index"
              @click="SET_CURRENT_CITY(city)"
              >{{ city.title }}</b-dropdown-item
            >
          </b-dropdown>
        </b-col>
      </b-row>

      <div class="line"></div>

      <b-row class="main-nav" align-h="between" align-v="center">
        <b-col cols="12" md="auto">
          <b-nav>
            <router-link to="/">
              <div class="main-nav__logo">
                <img
                  class="main-nav__logo-img"
                  :src="require('@/assets/images/logo.png')"
                  alt=""
                />
              </div>
            </router-link>

            <div v-for="(link, index) in links" :key="index">
              <b-nav-item
                class="main-nav-link"
                :class="link.path == $router.path ? 'active' : ''"
                v-if="!link.dropdown"
                :to="link.path"
              >
                {{ link.name }}</b-nav-item
              >
            </div>
          </b-nav>
        </b-col>
        <!-- phone -->
        <div class="d-flex align-items-center">
          <a class="main-nav__phone" :href="`tel:${phone}`">{{ phone }}</a>

          <!-- account -->
          <div class="ml-3">
            <b-button
              v-if="user"
              v-b-toggle.sidebar-menu
              variant="outline-primary"
              pill
              >{{ user.username }}</b-button
            >

            <b-button v-else to="/login" variant="outline-primary" pill>
              {{ "Войти" }}
            </b-button>
          </div>
        </div>
      </b-row>
    </b-container>

    <!-- Mobile -->
    <div class="d-lg-none">
      <b-container>
        <b-row align-h="between" align-v="center">
          <b-col cols="2" md="auto">
            <b-dropdown
              class="global-nav__link"
              variant="link"
              v-if="currentCity"
              :text="currentCity.title"
            >
              <b-dropdown-item
                :active="currentCity.id == city.id"
                v-for="(city, index) in cities"
                :key="index"
                @click="SET_CURRENT_CITY(city.id)"
                >{{ city.title }}</b-dropdown-item
              >
            </b-dropdown>
          </b-col>

          <b-col cols="auto">
            <button class="burger" v-b-modal.mobileMenu>
              <div class="burger-line"></div>

              <div class="burger-line"></div>

              <div class="burger-line"></div>
            </button>
          </b-col>
        </b-row>
      </b-container>

      <b-modal hide-footer hide-header id="mobileMenu">
        <b-container>
          <button
            class="close-modal-mobile"
            @click="
              () => {
                $bvModal.hide('mobileMenu');
              }
            "
          >
            x
          </button>

          <b-row class="mobile-modal-header mb-4 mt-3">
            <b-col cols="auto">
              <a
                @click="
                  () => {
                    $router.push(`/`).catch(() => {});
                    $bvModal.hide('mobileMenu');
                  }
                "
              >
                <div class="main-nav__logo">
                  <img
                    class="main-nav__logo-img"
                    :src="require('@/assets/images/logo.png')"
                    alt=""
                  />
                </div>
              </a>
            </b-col>
          </b-row>

          <b-row class="global-nav" align-h="between" align-v="center">
            <b-col cols="12" md="auto">
              <router-link class="global-nav__link" to="/"
                >Клиентам</router-link
              >
              <router-link class="global-nav__link" to="/partners"
                >Партнерам</router-link
              >
            </b-col>
          </b-row>

          <div class="line"></div>

          <b-row class="main-nav" align-h="between" align-v="center">
            <b-col cols="12">
              <div v-for="(link, index) in links" :key="index">
                <a
                  class="main-nav-link"
                  :class="link.path == $router.path ? 'active' : ''"
                  @click="
                    () => {
                      $router.push(`${link.path}`).catch(() => {});
                      $bvModal.hide('mobileMenu');
                    }
                  "
                >
                  {{ link.name }}
                </a>
              </div>
            </b-col>

            <!-- account -->
            <div>
              <b-button
                v-if="user"
                v-b-toggle.sidebar-menu
                variant="outline-primary"
                pill
                >{{ user.name }}</b-button
              >

              <b-button v-else to="/login" variant="outline-primary" pill>
                {{ "Войти" }}
              </b-button>
            </div>
          </b-row>
        </b-container>
      </b-modal>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters, mapMutations, mapActions } from "vuex";
export default {
  name: "MainNavTop",
  data() {
    return {
      links: [],
    };
  },
  computed: {
    ...mapState({
      user: (state) => state.auth.user,
      cities: (state) => state.cities.all,
      currentCity: (state) => state.cities.current,
    }),
    ...mapGetters(["percentRate", "phone"]),
  },
  methods: {
    ...mapActions([]),
    ...mapMutations(["SET_CURRENT_CITY"]),
  },
  watch: {
    percentRate: {
      immediate: true,
      deep: true,
      handler(rate) {
        if (rate) {
          this.links = [
            { name: "Новостройки", path: "/projects" },
            { name: "Квартиры", path: "/flats" },
            {
              name: `Ипотека от ${rate}%`,
              path: "/mortgage",
            },
            { name: "Офисы продаж", path: "/contacts" },
          ];
        }
      },
    },
  },
};
</script>

<style lang="scss">
//global nav
.global-nav {
  padding-top: 5px;
  padding-bottom: 5px;
}
// global nav link
.global-nav__link {
  font-size: 12px;
  font-weight: 400;
  color: rgba($zinc500, 0.8);

  margin-left: 15px;
}
.global-nav__link:hover {
  color: rgba($accent, 1);
  text-decoration: none;
}
.global-nav__link:first-child {
  margin-left: 0;
}

.global-nav__link {
  .btn.btn-link {
    font-size: 12px;
    line-height: normal;
    color: rgba($accent, 1);
    text-decoration: none;

    padding: 0;
    margin: 0;

    border: none;
  }
}

// main nav
.main-nav {
  display: flex;

  padding-top: 20px;
  padding-bottom: 20px;
}
.main-nav__space {
  height: 120px;
}

.main-nav-top {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;

  background-color: rgba($color: $white, $alpha: 1);
  box-shadow: $shadow;
  z-index: 1000;
}

.main-nav__logo {
  position: relative;

  width: 130px;
  height: 40px;
}
.main-nav__logo-img {
  position: absolute;

  height: 100%;
  width: 100%;

  object-fit: contain;
}

.main-nav-link a {
  font-weight: 600;
  font-size: 14px;
  text-decoration: none;

  margin-left: 15px;

  color: rgba($zinc800, 0.9);

  &.router-link-active {
    color: $accent;
  }

  &:hover {
    color: rgba($zinc800, 1);
  }
}

.main-nav__phone {
  color: rgba($accent, 1);
  margin-left: 5px;
  font-size: 16px;
  font-weight: 700;
}

.burger {
  position: relative;
  overflow: hidden;
  margin: 15px;
}
.burger-line {
  height: 2.5px;

  margin: 5px;

  width: 34px;

  border-radius: $radius;
  background-color: rgba($accent, 1);
}

.admin-link {
  font-size: 12px;
  font-weight: 500;
  color: rgba($zinc600, 1);
  padding: 5px;
}

.close-modal-mobile {
  display: block;
  position: absolute;
  top: 15px;
  right: 30px;

  font-weight: 300;

  font-size: 20px;

  color: rgba($zinc600, 1);
}

@media (max-width: 768px) {
  .main-nav__space {
    height: 0;
  }
  .main-nav-top {
    border-radius: 0;
  }
  .global-nav__link .btn.btn-link {
    font-size: 14px;
  }
  .main-nav__logo {
    width: 200px;
    height: 40px;
  }

  .main-nav-link {
    font-size: 20px;
    font-weight: 500;
    text-decoration: none;

    margin-left: 0;
    padding-bottom: 15px;

    display: block;

    color: rgba($zinc800, 0.9);

    &.router-link-exact-active {
      color: $accent;
    }

    &:hover {
      color: rgba($zinc800, 1);
    }

    .mobile-modal-header {
      position: relative;
    }

    .main-nav__phone {
      font-size: 32px;
    }
  }
}
</style>
