<template>
  <div class="contacts height-100" id="map">
    <b-container class="pt-4">
      <b-row>
        <b-col>
          <h1 class="block-title pb-4">Офисы продаж</h1>
        </b-col>
      </b-row>

      <b-row align-h="center" class="offices-map-row">
        <b-col order="1" order-md="0" class="markers-menu" cols="12" md="4">
          <div
            v-for="(city, index) in cities"
            :key="index"
            class="white-card p-3 mb-3"
          >
            <button
              class="office-marker__menu_city"
              block
              v-b-toggle="`offices-collapse_${city.id}`"
            >
              {{ city.title }}
            </button>

            <b-collapse
              accordion="offices-accordion"
              :visible="currentCity && currentCity.id == city.id"
              :id="`offices-collapse_${city.id}`"
            >
              <button
                :class="curerntOffice == office ? 'active' : ''"
                class="office-marker__button"
                v-for="(office, index) in offices.filter((el) => {
                  return el.city.id == city.id;
                })"
                :key="index"
                block
                type="button"
                @click="updateCoords(office)"
              >
                {{ office.address }}
              </button>
            </b-collapse>
          </div>
        </b-col>

        <b-col cols="12" md="8">
          <div class="white-card map mb-5" id="map">
            <yandex-map
              :settings="settings"
              :coords="mapCenterCoords"
              :zoom="15"
              :scroll-zoom="false"
            >
              <ymap-marker
                v-for="(office, index) in offices"
                :key="index"
                :marker-id="`office_${office.id}`"
                :coords="office.lat_long"
              />
            </yandex-map>
          </div>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import api from "@/api/axios";
import { mapState } from "vuex";
import { yandexMap, ymapMarker } from "vue-yandex-maps";

export default {
  components: { yandexMap, ymapMarker },
  data() {
    return {
      curerntOffice: null,

      // Yandex Map
      settings: {
        apiKey: process.env.VUE_APP_YMAPS_API_KEY,
        lang: "ru_RU",
        coordorder: "latlong",
        mapType: "map",
        version: "2.1",
      },
      mapCenterCoords: [53.71549662536345, 91.43366990185235],

      // Offiecs
      offices: [],
    };
  },
  computed: {
    ...mapState({
      cities: (state) => state.cities.all,
      currentCity: (state) => state.cities.current,
    }),
  },
  watch: {
    currentCity: {
      immediate: true,
      async handler(city) {
        if (city) {
          const { data } = await api.get("offices");
          this.offices = data;
          // Find office by city ID
          const office = this.offices.find((el) => {
            return el.city.id == city.id;
          });

          if (office) {
            this.updateCoords(office);
          }
        }
      },
    },
  },
  methods: {
    updateCoords(office) {
      this.curerntOffice = office;
      this.mapCenterCoords = office.lat_long;
    },
  },
};
</script>

<style lang="scss">
.contacts {
  background-color: rgba($zinc100, 1);
}
.offices-map-row {
  padding-bottom: 15vh;
}

// Left menu
.markers-menu {
  max-height: 60vh;
  overflow-y: scroll;
}
.office-marker__menu_city {
  text-align: start;
  display: block;
  padding: 10px 10px;

  font-weight: 600;
  font-size: 17px;

  width: 100%;
}
.office-marker__button {
  text-align: start;
  display: block;
  padding: 10px 10px;

  font-size: 14px;

  width: 100%;
  transition: all ease-out 100ms;
}
.office-marker__button:not(:last-child) {
  border-bottom-width: 1px;
  border-bottom-color: rgba($zinc200, 1);
  border-bottom-style: solid;
  transition: all ease-out 100ms;
}
.office-marker__button.active {
  color: rgba($accent, 1);

  transition: all ease-out 100ms;
}

@media (max-width: 768px) {
  // Left menu
  .markers-menu {
    max-height: 100%;
    overflow-y: auto;
  }
}
</style>
