// appConfig
import api from "@/api/axios";

export default {
  state: {
    config: null,
  },
  getters: {},
  mutations: {
    SET_APP_CONFIG(state, payload) {
      state.config = payload;
    },
  },
  actions: {
    async getAppConfig({ commit }) {
      try {
        const { data } = await api.get("app-configs/1");
        commit("SET_APP_CONFIG", data);
      } catch (e) {
        console.error(e);
        throw e;
      }
    },
    async saveConfig({ dispatch }, config) {
      try {
        await api.put(`app-configs/${config.id}`, config);
        await dispatch("getAppConfig");
      } catch (e) {
        console.error(e);
        throw e;
      }
    },
  },
};
