// cities
import api from "@/api/axios";

export default {
  state: {
    all: [],
    current: null,
  },
  getters: {},
  mutations: {
    SET_ALL_CITIES(state, cities) {
      state.all = cities;
    },
    SET_CURRENT_CITY(state, city) {
      localStorage.setItem("city_id", city.id);
      state.current = city;
    },
  },
  actions: {
    async getCities({ commit }) {
      try {
        const { data } = await api.get("cities");
        commit("SET_ALL_CITIES", data);
      } catch (e) {
        console.error(e);
        throw e;
      }
    },
    initCurrentCity({ state, commit }) {
      let currentCity;
      // Get saved city ID
      const cityId = localStorage.getItem("city_id");

      // If cities not exist
      if (!state.all.length) {
        return null;
      }
      // Find city object
      currentCity = state.all.find((el) => {
        return el.id == cityId;
      });
      // If city not found by ID
      if (!currentCity) {
        currentCity = state.all[0];
      }

      commit("SET_CURRENT_CITY", currentCity);
    },
    async createCity({ dispatch }, city) {
      try {
        await api.post("cities", city);
        dispatch("getCities");
      } catch (e) {
        console.log(e);
        throw e;
      }
    },
    async updateCity({ dispatch }, city) {
      try {
        await api.put(`cities/${city.id}`, city);
        dispatch("getCities");
      } catch (e) {
        console.log(e);
        throw e;
      }
    },
    async deleteCity({ dispatch }, city) {
      try {
        await api.delete(`cities/${city.id}`);
        await dispatch("getCities");
        dispatch("initCurrentCity");
      } catch (e) {
        console.log(e);
        throw e;
      }
    },
  },
};
