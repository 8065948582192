<template>
  <!-- Калькулятор компонент -->
  <div class="mtg-calculator">
    <b-container>
      <b-row
        align-h="start"
        align-v="center"
        class="height-100 mtg-calculator-row"
      >
        <b-col cols="12">
          <b-row>
            <b-col>
              <h2 class="block-title pb-5">Посчитайте вашу выгоду</h2>
            </b-col>
          </b-row>

          <b-row align-v="start">
            <b-col cols="12" lg="3" class="pb-4">
              <div class="white-card calculator-card">
                <div class="mtg-calculator-label">Стоимость</div>

                <div v-if="price" class="mtg-calculator-value">
                  {{ printPay(+price) }}
                </div>

                <b-form-input
                  v-model="price"
                  type="range"
                  min="1500000"
                  max="30000000"
                ></b-form-input>
              </div>

              <div class="white-card calculator-card">
                <div class="mtg-calculator-label">Срок</div>

                <div class="mtg-calculator-value">
                  {{ period }} {{ period < 5 ? "год" : "лет" }}
                </div>

                <b-form-input
                  v-model="period"
                  type="range"
                  min="1"
                  max="30"
                ></b-form-input>
              </div>

              <b-button
                v-b-modal.mortgage-calculate
                type="button"
                variant="primary"
                block
                pill
              >
                Получить одобрение
              </b-button>
            </b-col>

            <b-col cols="12" lg="9">
              <b-row cols="1" cols-md="2">
                <!-- base -->
                <b-col class="pb-4">
                  <div class="white-card offer-card">
                    <div class="offer-title">
                      Стандартная ипотека
                      <br />

                      <span class="offer-subtitle">
                        С первоначальнм взносом
                      </span>
                    </div>

                    <div class="offer-info">
                      <div class="offer-info-label">Процентная ставка</div>

                      <div class="offer-info-value">{{ baseOffer.stake }}%</div>
                    </div>

                    <div class="offer-info">
                      <div class="offer-info-label">Ежемесячный платеж</div>

                      <div class="offer-info-value">
                        {{ printPay(baseOffer.payment) }}
                      </div>
                    </div>

                    <div class="offer-economy">
                      <div class="offer-economy__label">
                        Первоначальный взнос
                      </div>

                      {{ printPay(baseOffer.contrib) }}
                    </div>
                  </div>
                </b-col>

                <!-- spec -->
                <b-col class="pb-4">
                  <div class="white-card offer-card offer-spec-card">
                    <!-- белая иконка ип отдела на фоне -->
                    <div class="offer-title spec">
                      Ипотека от {{ percentRate }}%
                      <br />

                      <span class="offer-subtitle spec">
                        Без первоначального взноса 0 ₽
                      </span>
                    </div>

                    <div>
                      <div class="offer-info">
                        <div class="offer-info-label spec">
                          Процентная ставка
                        </div>

                        <div class="offer-info-value spec">
                          {{ percentRate }}%
                        </div>
                      </div>

                      <div class="offer-info">
                        <div class="offer-info-label spec">
                          Ежемесячный платеж
                        </div>

                        <div class="offer-info-value spec">
                          {{ printPay(specOffer.payment) }}
                        </div>
                      </div>

                      <div class="offer-economy spec">
                        <div class="offer-economy__label spec">
                          Без первоначального взноса
                        </div>
                        {{ printPay(specOffer.contrib) }}
                      </div>
                    </div>
                  </div>
                </b-col>
              </b-row>
            </b-col>

            <b-modal id="mortgage-calculate" hide-footer centered>
              <b-container>
                <b-row>
                  <b-col>
                    <b-form
                      @submit.prevent="
                        sendForm(
                          `Со страницы ипотека, калькулятор ипотеки:<br />
                            Стоимость: ${printPay(+price)}<br />
                            Cрок: ${period}`
                        )
                      "
                    >
                      <b-form-group label="Имя:">
                        <b-form-input
                          v-model="form.name"
                          type="text"
                          placeholder="Ваше имя"
                          required
                        ></b-form-input>
                      </b-form-group>

                      <b-form-group label="Телефон:">
                        <b-form-input
                          v-model="form.phone"
                          v-mask="'+7 (___) ___ __ __'"
                          type="tel"
                          placeholder="+7 999 999 99 99"
                          required
                        ></b-form-input>
                      </b-form-group>

                      <b-button type="submit" variant="primary" block pill>
                        {{ calcButtonText }}
                      </b-button>
                    </b-form>
                  </b-col>
                </b-row>
              </b-container>
            </b-modal>
          </b-row>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import api from "@/api/axios";
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      calcButtonText: "Получить одобрение",
      form: {
        name: "",
        phone: "",
      },

      price: 5000000,
      period: 15,
    };
  },
  computed: {
    ...mapGetters(["percentRate"]),
    currentCity() {
      return this.$store.state.cities.current;
    },

    specOffer() {
      // Считаем первоначальный взнос
      const stake = this.percentRate;
      let minContribPercent = 15; // Минимальный процент предоплаты
      // Ищем размер предоплаты от текущей выбранной цены
      let contribValue = (this.price * (minContribPercent / 100)).toFixed(0);

      // Обязательная минимальная предоплата 750т.р.
      if (contribValue < 750000) {
        contribValue = 750000;
      }

      const creditSum = +this.price + +contribValue;

      const mnthSt = stake / 12 / 100;
      const mainSt = Math.pow(1 + +mnthSt, this.period * 12);

      const payment = (creditSum * mnthSt * mainSt) / (mainSt - 1);
      const outPay = payment * (this.period * 12) - creditSum;

      const economy = this.baseOffer.outPay - outPay;

      return {
        stake,
        payment,
        outPay,
        economy,
        contrib: 0,
        sum: creditSum,
      };
    },
    baseOffer() {
      // Стандартная процентная ставка
      const stake = 5.99;
      // Считаем стандартный первоначальный взнос
      const contribPercent = 20;
      const contribValue = +(this.price * (contribPercent / 100)).toFixed(0);

      const creditSum = +this.price - +contribValue;

      const mnthSt = stake / 12 / 100;
      const mainSt = Math.pow(1 + +mnthSt, this.period * 12);

      const payment = (creditSum * mnthSt * mainSt) / (mainSt - 1); // Платеж
      const outPay = payment * (this.period * 12) - creditSum; // Переплата

      return {
        stake,
        payment,
        outPay,
        contrib: contribValue,
        sum: creditSum,
        economy: 0,
      };
    },
  },
  methods: {
    async sendForm(comment) {
      try {
        this.$store.commit("LOADING", true);

        this.form.comment = comment;
        this.form.city = this.currentCity.title;
        this.form.page = `${this.$store.state.domain}${this.$route.fullPath}`;

        await api.post("callback-form", this.form);

        this.calcButtonText = "Заявка отправлена";
        this.$bvToast.toast("Успешно");
        (this.form = {
          name: "",
          phone: "",
        }),
          this.$store.commit("LOADING", false);
      } catch (e) {
        this.$store.commit("LOADING", false);
        console.error(e);
        this.$bvToast.toast(`Ошибка: ${e.response.data.message}`);
      }
    },
    printPay(int) {
      if (int > 0) {
        int = +int.toFixed(0);
      }

      return new Intl.NumberFormat("ru-RU", {
        style: "currency",
        currency: "RUB",
        maximumSignificantDigits: 10,
      }).format(int);
    },
  },
  mounted() {
    this.calcButtonText = "Получить одобрение";
  },
};
</script>

<style lang="scss">
// calculator
.mtg-calculator {
  background-color: rgba($color: $zinc100, $alpha: 1);
}
.mtg-calculator-row {
  padding-top: 100px;
  padding-bottom: 100px;
}

.mtg-block-title {
  color: rgba($zinc800, 1);

  font-size: 22px;
  font-weight: 600;
}
.mtg-block-title.accent {
  color: rgba($accent, 0.75);

  font-size: 36px;
  font-weight: 800;

  text-transform: uppercase;
}
.mtg-block-subtitle {
  color: rgba($zinc600, 1);

  font-size: 14px;
  font-weight: 500;
}
.mtg-calculator-label {
  color: rgba($zinc600, 0.8);
  font-weight: 500;
  font-size: 13px;
  padding-bottom: 10px;
}
.mtg-calculator-value {
  color: rgba($zinc800, 0.9);
  font-weight: 700;
  font-size: 22px;
}
.calculator-card {
  padding: 30px;
  padding-bottom: 15px;

  margin-bottom: 15px;
}

// Offer
.offer-card {
  min-height: 100%;
  padding: 30px;

  box-shadow: $shadow;

  background-color: rgba($color: $white, $alpha: 0.3);
}
.offer-spec-card {
  background-color: $accent !important;
  box-shadow: $shadow-accent-md;
}
.offer-title {
  padding-bottom: 30px;
  font-size: 26px;

  font-weight: 600;

  color: rgba($zinc800, 0.8);
}
.offer-title.spec {
  color: rgba($zinc100, 1);
  font-size: 26px;
  font-weight: 800;
}
.offer-subtitle {
  font-size: 14px;
  font-weight: 600;

  color: rgba($zinc500, 1);
}
.offer-subtitle.spec {
  font-size: 14px;
  font-weight: 600;

  color: rgba($zinc100, 1);
}
.offer-info {
  padding-bottom: 15px;
}

.offer-info-label {
  color: rgba($zinc500, 0.8);

  font-size: 13px;
  font-weight: 400;
}
.offer-info-label.spec {
  color: rgba($zinc200, 0.9);
}
.offer-info-value {
  color: rgba($zinc800, 1);

  font-size: 19px;
  font-weight: 600;
}
.offer-info-value.spec {
  color: rgba($zinc100, 1);
}

.offer-economy {
  background-color: rgba($color: $zinc200, $alpha: 0.6);
  color: rgba($zinc800, 1);
  border-radius: $radius;

  font-size: 32px;
  line-height: 32px;
  font-weight: 700;

  padding: 13px 30px;

  margin-top: 30px;
}
.offer-economy.spec {
  // color: rgba($zinc800, 0.9);
  color: rgba($accent, 1);

  background-color: rgba($color: $zinc100, $alpha: 1);
}
.offer-economy__label {
  font-size: 11px;
  line-height: 11px;
  padding-bottom: 5px;
  font-weight: 600;
}
.offer-economy__label.spec {
  color: rgba($accent, 1);
}
</style>
