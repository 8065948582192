/**
 * router/index.js
 */

/* global ym */

import Vue from "vue";
import VueRouter from "vue-router";
import store from "@/store";
import baseRoutes from "./baseRoutes";
import partnerRoutes from "./partnerRoutes";
import adminRoutes from "./adminRoutes";
import landingRoutes from "./landingRoutes";

const routes = baseRoutes.concat(adminRoutes, partnerRoutes, landingRoutes);

Vue.use(VueRouter);

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to) {
    if (to.hash) {
      return {
        selector: to.hash,
        behavior: "smooth",
      };
    } else {
      return { x: 0, y: 0 };
    }
  },
});

const ERROR_ROUTE_NAME = "error";

async function isAuthenticated() {
  await store.dispatch("getUser");
  return store.state.auth.user;
}

function isAuthorized(user, requiredRoles) {
  return requiredRoles.some((role) =>
    user.roles.some((userRole) => userRole.name === role)
  );
}

router.beforeEach(async (to, from, next) => {
  try {
    const requiresAuth = to.meta.auth;
    const requiredRoles = to.meta.roles || [];

    if (requiresAuth) {
      const user = await isAuthenticated();

      if (
        !user ||
        (requiredRoles.length && !isAuthorized(user, requiredRoles))
      ) {
        const errorMessage = "Ошибка 403: Доступ запрещен";
        sessionStorage.setItem("errorMessage", errorMessage);

        return next({ name: ERROR_ROUTE_NAME });
      }
    }

    next();
  } catch (error) {
    console.error(error);
    return next({ name: "error" });
  }
});

router.afterEach((to) => {
  // Проверка, что функция ym доступна
  if (typeof ym !== "undefined") {
    // Использование id счётчика и функции hit для отправки информации о просмотре страницы
    ym(process.env.VUE_APP_YANDEX_METRIKA, "hit", to.fullPath);
  }
});

export default router;
