/**
 * router/partnerRoutes.js
 */

const partnerRoutes = [
  {
    path: "/partners",
    name: "partners",
    component: () => import("../views/Partners/PartnersView.vue"),
  },
  {
    path: "/partners/form",
    name: "partners.form",
    meta: { auth: true },
    component: () => import("../views/Partners/PartnersFormView.vue"),
  },
  {
    path: "/partners/flats",
    name: "partners.flats",
    meta: { auth: true, roles: ["partner"] },
    component: () => import("../views/Flats/FlatsView.vue"),
  },
  {
    path: "/partners/flats/:id",
    name: "partners.flatDetail",
    meta: { auth: true, roles: ["partner"] },
    component: () => import("../views/Flats/FlatDetailView.vue"),
  },
  {
    path: "/partners/booked-flats",
    name: "partners.bookedFlats",
    meta: { auth: true, roles: ["partner"] },
    component: () => import("../views/Partners/BookedFlatsView.vue"),
  },
];

export default partnerRoutes;
