// Main import
import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
// BootstrapVue
import "./plugins/bootstrap-vue";
import "@babel/polyfill";
import "mutationobserver-shim";
// Yandex Metrika
import "./plugins/yandex-metrika";
// Bitrix24 Chat
import "./plugins/b24-chat";
// Moment
import moment from "moment";
moment.locale("ru");
// Phone formatter
import "./plugins/phone-formatter";
// import function to register Swiper custom elements
import { register } from "swiper/element/bundle";
// register Swiper custom elements
register();

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
