/**
 * router/landingRoutes.js
 */

const landingRoutes = [
  {
    path: "/one-rouble",
    name: "oneRouble",
    component: () => import("../views/Landing/OneRouble.vue"),
  },
  {
    path: "/mortgage-help",
    name: "mortgageHelp",
    component: () => import("../views/Landing/MortgageHelp.vue"),
  },
  {
    path: "/invite-friend",
    name: "inviteFriend",
    component: () => import("../views/Landing/InviteFriend.vue"),
  },
  {
    path: "/double-pay",
    name: "doublePay",
    component: () => import("../views/Landing/DoublePay.vue"),
  },
];

export default landingRoutes;
