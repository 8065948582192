<template>
  <b-sidebar id="sidebar-menu" backdrop shadow>
    <div class="p-3">
      <b-nav vartical>
        <!-- User -->
        <b-card class="w-100 mb-3">
          <b class="ml-3">Общее</b>
          <b-nav-item
            v-if="hasAnyRole(['employee', 'partner', 'admin', 'clerk'])"
            to="/purchased-flats"
            >Выкупленные квартиры</b-nav-item
          >
        </b-card>
        <!-- Partner -->
        <b-card class="w-100 mb-3">
          <b class="ml-3">Партнер</b>
          <b-nav-item to="/partners/form">Заявка на партнерство</b-nav-item>
          <div v-if="isPartner">
            <b-nav-item to="/partners/booked-flats"
              >Мои заявки на бронь</b-nav-item
            >
            <b-nav-item to="/partners/flats">Подать заявку на бронь</b-nav-item>
          </div>
        </b-card>
        <!-- Admin -->
        <b-card
          v-if="isAdmin"
          bg-variant="dark"
          text-variant="white"
          class="w-100"
        >
          <b class="ml-3">Админ</b>
          <b-nav-item to="/admin/main">Общие настройки</b-nav-item>
          <b-nav-item to="/admin/cities">Города</b-nav-item>
          <b-nav-item to="/admin/offices">Офисы</b-nav-item>
          <b-nav-item to="/admin/projects">Новостройки</b-nav-item>
          <b-nav-item to="/admin/flats">Квартиры</b-nav-item>
        </b-card>
      </b-nav>
    </div>
    <template v-if="user" #footer>
      <div
        class="d-flex bg-dark text-light align-items-center justify-content-between px-3 py-2"
      >
        <div>
          <div>
            <strong class="mr-auto">{{ user.name }}</strong>
          </div>
          <div>
            <small>{{ user.email }}</small>
          </div>
        </div>
        <b-button size="sm" @click="logoutUser()">Выйти</b-button>
      </div>
    </template>
  </b-sidebar>
</template>

<script>
import { mapState, mapGetters, mapActions } from "vuex";

export default {
  data: () => ({}),
  computed: {
    ...mapState({
      user: (state) => state.auth.user,
    }),
    ...mapGetters(["isAdmin", "isPartner", "hasAnyRole"]),
  },
  methods: {
    ...mapActions(["logout"]),
    async logoutUser() {
      try {
        this.$store.commit("LOADING", true);
        await this.logout();
        this.$router.push(`/login`);
      } catch (e) {
        console.error(e);
        this.$bvToast.toast(`Ошибка: ${e.response.data.message}`);
      } finally {
        this.$store.commit("LOADING", false);
      }
    },
  },
};
</script>
