<template>
  <div class="footer">
    <b-container>
      <b-row class="footer-row" align-h="between" align-v="start">
        <b-col class="footer-col" cols="9" md="5" lg="3">
          <div>
            <img :src="require(`@/assets/images/logo_white.png`)" />
          </div>
        </b-col>

        <b-col class="footer-col" cols="12" lg="auto">
          <div class="footer-contact" v-if="phone">
            <span class="footer-contact-label">Телефон </span>

            <a class="footer-contact-value" :href="`tel:${phone}`">{{
              phone
            }}</a>
          </div>

          <div class="footer-contact" v-if="email">
            <span class="footer-contact-label">Email </span>

            <a class="footer-contact-value" :href="`mailto:${email}`">{{
              email
            }}</a>
          </div>
        </b-col>

        <b-col class="footer-col" cols="12" lg="auto">
          <div class="footer-copyright">
            © {{ copyright.year.from }}-{{ copyright.year.to }},
            {{ copyright.company }}
          </div>

          <div>
            <router-link class="footer-second-data" to="#"
              >Политика конфиденциальности</router-link
            >
          </div>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      copyright: {
        company: `ООО «Ипотечный отдел»`,
        year: {
          from: "2009",
          to: new Date().getFullYear(),
        },
      },
    };
  },
  computed: {
    ...mapGetters(["phone", "email"]),
  },
  mounted() {},
};
</script>

<style lang="scss">
.footer {
  color: rgba($zinc100, 0.9);
  font-size: 18px;
  font-weight: 500;

  background-color: $accent;
}
.footer-row {
  padding-top: 70px;
  padding-bottom: 50px;
}
.footer-col {
  padding-bottom: 30px;
}

.footer-contact {
  position: relative;
  margin-bottom: 5px;
}
.footer-contact-label {
  line-height: normal;
  display: block;
  color: rgba($zinc100, 0.6);
  font-size: 10px;
  font-weight: 500;

  text-transform: uppercase;
}

.footer-contact-value {
  position: relative;
  color: rgba($zinc100, 0.9);
  font-size: 18px;
  font-weight: 500;
}

.footer-copyright {
  text-transform: uppercase;

  color: rgba($zinc100, 0.8);
  font-size: 16px;
  font-weight: 400;
}
.footer-second-data {
  color: rgba($zinc100, 0.9);
  font-size: 14px;
  font-weight: 600;
}
</style>
