export default {
  state: {
    fields: [
      // Required fields
      {
        key: "id",
        label: "ID",
        width: "70px",
        sortable: false,
        disabled: true,
        stickyColumn: false,
        visible: true,
      },
      {
        key: "project.builder",
        label: "Застройщик",
        sortable: false,
        width: "200px",
        type: "relation",
        stickyColumn: false,
        visible: true,
      },
      {
        key: "project.title",
        label: "Проект",
        sortable: false,
        width: "200px",
        type: "relation",
        stickyColumn: false,
        visible: true,
      },
      {
        key: "address",
        label: "Адрес",
        sortable: false,
        width: "200px",
        type: "",
        stickyColumn: true,
        visible: true,
      },
      {
        key: "room_count",
        label: "Кол-во комнат",
        width: "70px",
        sortable: false,
        stickyColumn: false,
        visible: true,
      },
      {
        key: "flat_number",
        label: "Номер кв.",
        width: "70px",
        sortable: false,
        stickyColumn: false,
        visible: true,
      },
      {
        key: "register_date",
        label: "Дата регистрации",
        type: "date",
        width: "150px",
        sortable: false,
        stickyColumn: false,
        visible: true,
      },
      {
        key: "area",
        label: "Площадь",
        width: "80px",
        sortable: false,
        stickyColumn: false,
        visible: true,
      },
      {
        key: "floor",
        label: "Этаж",
        width: "60px",
        sortable: false,
        stickyColumn: false,
        visible: true,
      },
      {
        key: "cost_without_payment",
        label: "Стоимость без ПВ",
        type: "money",
        sortable: false,
        stickyColumn: false,
        visible: true,
      },
      {
        key: "cost_price",
        label: "Себестоимость",
        type: "money",
        sortable: false,
        stickyColumn: false,
        visible: true,
      },
      {
        key: "cost_in_joa",
        label: "Стоимость в ДДУ",
        type: "money",
        sortable: false,
        stickyColumn: false,
        visible: true,
      },
      // {
      //   key: "booking_date",
      //   label: "Дата брони",
      //   type: "date",
      //   width: "150px",
      //   sortable: false,
      //   stickyColumn: false,
      //   visible: true,
      // },
      // {
      //   key: "booking_time",
      //   label: "Время брони",
      //   type: "time",
      //   width: "130px",
      //   sortable: false,
      //   stickyColumn: false,
      //   visible: true,
      // },
      {
        key: "deposit_date",
        label: "Дата задатка",
        type: "date",
        width: "150px",
        sortable: false,
        stickyColumn: false,
        visible: true,
      },
      {
        key: "agency",
        label: "Агентский",
        sortable: false,
        stickyColumn: false,
        visible: true,
      },
      {
        key: "manager_office",
        label: "Менеджер/Офис",
        sortable: false,
        stickyColumn: false,
        visible: true,
      },
      {
        key: "client",
        label: "Клиент",
        sortable: false,
        stickyColumn: false,
        visible: true,
      },
      {
        key: "agency_percentage",
        label: "Процент агенства",
        width: "70px",

        sortable: false,
        stickyColumn: false,
        visible: true,
      },
      {
        key: "agency_percentage_value",
        label: "Доля агенства",
        width: "120px",
        type: "calculate",
        sortable: false,
        stickyColumn: false,
        visible: true,
      },
      // Additional fields
      {
        key: "purchased",
        label: "Выкупленная",
        width: "70px",
        sortable: false,
        type: "bool",
        stickyColumn: false,
        visible: true,
      },
      {
        key: "hidden",
        label: "Скрыта/Забронирована навсегда",
        width: "70px",
        sortable: false,
        type: "bool",
        stickyColumn: false,
        visible: true,
      },
      {
        key: "hidden_until",
        label: "Скрыта/Забронирована до",
        sortable: false,
        type: "datetime",
        width: "220px",
        stickyColumn: false,
        disabled: true,
        visible: true,
      },
      {
        key: "debt_transfer",
        label: "С переводом долга",
        width: "80px",
        type: "bool",
        sortable: false,
        stickyColumn: false,
        visible: true,
      },
      {
        key: "files",
        label: "Файлы/Эскроу пополнен",
        width: "300px",
        sortable: false,
        type: "file",
        stickyColumn: false,
        visible: true,
      },
    ],
  },
};
