import api from "@/api/axios";

export default {
  state: {
    all: [],
    project: null,
  },
  mutations: {
    SET_ALL_PROJECTS(state, projects) {
      state.all = projects;
    },
    SET_PROJECT(state, project) {
      state.project = project;
    },
  },
  actions: {
    async getProjects({ rootState, commit }, params = {}) {
      try {
        params.city_id = rootState.cities.current.id;

        const { data } = await api.get("projects", { params });
        commit("SET_ALL_PROJECTS", data);
      } catch (e) {
        console.error(e);
        throw e;
      }
    },
    async getProjectById({ commit }, projectId) {
      try {
        const { data } = await api.get(`projects/${projectId}`);
        commit("SET_PROJECT", data);
      } catch (e) {
        console.error(e);
        throw e;
      }
    },
  },
};
